<template>
  <div class="signIn">
    <div class="body">
      <div
        v-if="authorized"
      >
        <h1 class="h1">Authorized</h1>
        <p class="bodyText">You can be start create new item.</p>
        <router-link
          class="button"
          tag="button"
          :to="{ name: 'my_treees', params: { userId }}">My items</router-link>
      </div>
      <div
        v-else
      >
        <h1 class="h1">Sign in to Treee</h1>
        <raised-button-primary
          type="button"
          @click="signInWithGoogleAccount"
        >sign in with google account</raised-button-primary>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import RaisedButtonPrimary from '../components/common/atoms/RaisedButtonPrimary'

const authStore = createNamespacedHelpers('authStore')

export default {
  components: {
    RaisedButtonPrimary
  },
  computed: {
    ...authStore.mapState([
      'authorized',
      'displayName',
      'userId'
    ])
  },
  mounted () {
    this.$nextTick(() => {
      if (!this.authorized) return
      this.$router.push({
        name: 'my_treees',
        params: {
          userId: this.userId
        }
      })
    })
  },
  methods: {
    ...authStore.mapActions([
      'signInWithGoogleAccount',
      'signOut'
    ])
  }
}
</script>

<style scoped lang="scss">
  @import "../styles/constants";

  .signIn {
    background-color: #fafafa;
    min-height: 100vh;
    min-width: 100vw;
    padding: 60px 0 0;
    box-sizing: border-box;
  }

  .body {
    border: solid 1px $stroke;
    width: 600px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    min-height: 400px;
    background-color: white;
    text-align: center;
  }

  .h1 {
    @extend %typo-h1;

    color: $primary-d;
    margin: 0 0 20px;
  }

  .bodyText {
    @extend %typo-body1;

    color: $primary-d;
    margin: 0 0 30px;
  }

  .button {
    @extend %typo-button;

    line-height: 1;
    border: none;
    box-sizing: border-box;
    height: 34px;
    min-width: 80px;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    background-color: $theme-a;
    color: $white;

    &:focus {
      outline: none;
    }

    &:hover {
      filter: brightness(95%);
    }

    &:active {
      filter: brightness(90%);
    }
  }
</style>
